<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Reporting Quality Control</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Produksi</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Production" class="font-weight-bold" @input="onChangeProduction()" :options="ProductionData" v-model="Production" />
                                <label id="errorProduction" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateFrom"
                                >
                                </date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>                            
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%;"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
                
                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                    </CCol>
                </CRow>
                <hr>
                
                <CRow>
                    <CCol>
                        <quality-control-report-grid ref = "qualityControlReportGrid"
                            :key="gridReload" 
                            :dateTo="DateToChangest" 
                            :dateFrom="DateFromChangest" 
                            :production="ProductionChangest"
                        />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import 'vue2-datepicker/index.css';
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import { report, datetime_zone } from '../../../infrastructure/constant/variable';

import QualityControlReportService from '../QualityControlReport/Script/QualityControlReportServices.js';
import QualityControlReportGrid from '../QualityControlReport/Grid/QualityControlReportGrid.vue';

export default {
    name: 'TransactionInvoicePaymentReport',
    components: {
        'quality-control-report-grid': QualityControlReportGrid,
        'date-picker': datePicker
    },
    data(){
        return{
            gridReload: 0,

            DateFrom: report.default_start_date,
            DateTo: report.default_end_date,

            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,

            DateTypeData: [],
            DateType: report.default_date,

            Production: '',
            ProductionData: [],
            ProductionChangest: '',
        }
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();

        this.ProductionData = await QualityControlReportService.getProductionQuery();
        this.Production = '';

        this.onChangeDateType();
    },
    methods: {
        async onChangeProduction(){
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            this.ProductionChangest = this.Production;

            this.gridReload++;
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);

            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;

            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            this.ProductionChangest = this.Production;

            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if ((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0) {
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            else {
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;                
                this.ProductionChangest = this.Production;

                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo() {
            var vue = this;
            if ((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0) {
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            else {
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;                
                this.ProductionChangest = this.Production;
                
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async exportExcel() {
            this.$refs.qualityControlReportGrid.exportExcel();
        },
    }
}
</script>

<style scoped>
</style>