import gql from 'graphql-tag';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class QualityControlService {
    readQuery(){
        var query = `
            query ($startDate:String!, $endDate:String!, $productionId:Int) {
                GetQualityControlReport(start_date:$startDate, end_date:$endDate, production_id:$productionId) {
                    production_mutation_id
                    production_mutation_code
                    production_mutation_date
                    production_date
                    status
                    notes
                    item_id
                    item_name
                    product_code
                    item_type
                    quantity
                    weight
                } 
            }
        `;
        return query;
    }

    async getProductionQuery(){
        var query = gql`
            query {
                GetMasterProductions {
                    productions {
                        production_id
                        production_code
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];

        if (result.data.GetMasterProductions != null) {
            for (let i = 0; i < result.data.GetMasterProductions.productions.length; i++) {
                var str = { 
                    value: result.data.GetMasterProductions.productions[i].production_id, 
                    label: result.data.GetMasterProductions.productions[i].production_code
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    qualityControlReportExcelHeaderSize(){
        var size = [
            {width: 25}, //colA
            {width: 25}, //colB
            {width: 25}, //colC
            {width: 25}, //colD
            {width: 25}, //colE
            {width: 25}, //colF
            {width: 25}, //colG
            {width: 25}, //colH
            {width: 25}, //colI
            {width: 25}, //colJ
            {width: 25}, //colK
        ];
        return size;
    }

    qualityControlReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'QUALITY CONTROL REPORT',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'KODE MUTASI',
            colB : 'TANGGAL MUTASI',
            colC : 'TANGGAL PRODUKSI',
            colD : 'STATUS',
            colE : 'KODE BARANG',
            colF : 'NAMA BARANG',
            colG : 'QUANTITY',
            colH : 'BERAT',
            colI : 'CATATAN',
            colJ : 'APPROVE OLEH',
        };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].production_mutation_code,
                    colB : kendo.toString(kendo.parseDate(data[i].production_mutation_date, 'yyyy-MM-dd'), 'dd-MM-yyyy'),
                    colC : kendo.toString(kendo.parseDate(data[i].production_date, 'yyyy-MM-dd'), 'dd-MM-yyyy'),
                    colD : data[i].status,
                    colE : data[i].product_code,
                    colF : data[i].item_name,
                    colG : { v: data[i].quantity, z: numberFormat },
                    colH : { v: data[i].weight, z: numberFormat },
                    colI : data[i].notes,
                    colJ : data[i].approved_by,
                };
                
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}


export default new QualityControlService();